<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h5 style="color: blue; margin-bottom: 30px">
          {{ $t("emploi.emplois") }}
        </h5>
        <div class="row mb-2">
          <div class="col">
            <label>{{ $t("emploi.listeClasses") }}</label>
            <a-select
              id="selectClass"
              show-search
              :placeholder="$t('emploi.listeClasses')"
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 150px; margin-left: 15px"
              @change="
                (val) => {
                  selectedClasse = val;
                  handleClassChange(val);
                }
              "
            >
              <a-select-option
                v-for="classe in classes"
                :key="classe._id"
                :value="classe._id"
                >{{ classe.name }}</a-select-option
              >
            </a-select>
            <div style="float: right">
              <a-button
                type="primary"
                icon="reset"
                :disabled="loadingMigrate"
                @click="handleMigrate"
                :loading="loadingMigrate"
              >
                {{ $t("smartSchedule.migrate") }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <schedule
        :loading="loading"
        :errorDrag="errorDrag"
        :dataGrid="data"
        :updating="updating"
        @sched="showUpdateDrawer"
        @sched_drag_update="handleUpdateDrag"
        @reload="
          () => {
            this.updating = false;
          }
        "
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import schedule from "./component/schedule";
import { mapState } from "vuex";

export default {
  name: "view-schedule",
  components: {
    schedule: schedule,
  },
  async created() {
    console.log(this.$route.params.schedParamId);

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            teachers: 1,
            level: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));
  },
  data() {
    return {
      migrated: false,
      loadingMigrate: false,
      teachers: [],
      data: [],
      classes: [],
      loadingSave: false,
      visibleModal: false,
      moment,
      selectedClasse: undefined,
      loading: false,
      errorDrag: false,
    };
  },
  computed: {
    ...mapState(["settings"]),
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOptionGroup(input, option) {
      if (option.componentOptions.children[0].text)
        return (
          option.componentOptions.children[0].text
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      return false;
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleClassChange(val) {
      const classroom = this.classes.find((c) => c._id === val);
      this.loading = true;
      apiClient
        .post("/smartSchedule/smartSchedTmp/filter", {
          query: {
            schedJobParam: this.$route.params.schedParamId,
            classeRoom: classroom.name + ":" + val,
            schoolarYear: this.settings.activeSchoolarYear,
          },
        })
        .then((res) => {
          this.data = [...res.data];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdateDrag(i, day, time, duration) {
      //
    },
    showUpdateDrawer(record, day, time) {
      //
    },
    handleMigrate() {
      this.loadingMigrate = true;
      apiClient
        .post(
          "/smartSchedule/schedJobParam/migrate/" +
            this.$route.params.schedParamId,
          {}
        )
        .then((res) => {
          this.migrated = true;
          this.$message.success(this.$t("smartSchedule.migrateSuccess"));
          this.$router.push({ path: "/schedule" });
        })
        .catch((err) => {
          this.$message.error(this.$t("error.error"));
        })
        .finally(() => {
          this.loadingMigrate = false;
        });
    },
  },
};
</script>

<style></style>
